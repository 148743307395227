.triangle-bottomright {
          position: fixed;
          /* display: none; */
          opacity: 0;
          visibility: hidden;
          bottom: 0;
          right: 0;
          width: 0;
          height: 0;
          border-bottom: 85px solid #d32e09f7;
          border-left: 85px solid transparent;
          transition: visibility 0.5s, opacity 0.5s linear;
    }
    .triangleText {
        position: fixed;
        transform: rotate(-45deg);
        bottom: 24px;
        right: 0px;
        color: white;
        font-weight: bold;
        text-align: right;
        max-width: 70px;
        font-size: 10px;
        cursor: pointer;
    }
