.skillBarsContainer {
  display: flex;
    background: #50394cBB;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 14px;
    color: #b2b2b2;
    max-width: 1000px;
    border-radius: 6px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    cursor: default;
}

.skills {
  width: 100%;
}

.title {
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.customUnderline {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}

