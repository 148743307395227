.titleBarContainer {
  position: relative;
    display: flex;
    flex-direction: column;
    background: #f4e1d2BB;
    color: #023246;
    min-height: 100px;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border-radius: 6px;
}

.titleBarName {
    margin: 10px 15px;
    font-weight: bold;
    font-size: 16px;
}

.titlePosititon {
    margin: 5px 20px;
    font-size: 16px;
}

.horizontalBreak {
    width: 96%;
    margin: 10px auto;
    text-align: center;
}

.titleAddress, .titleContacts {
    margin: 2px 20px;
    font-size: 16px;
}

.titleContacts {
    margin-bottom: 10px;
    font-size: 14px;
}

.titlePhoto {
  position: absolute;
  align-self: flex-end;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: red;
  margin-top: 20px;
  margin-left: -15px;
}
