.welcomeContainer {
    margin: 0;
    font-weight: 500;
    box-sizing: border-box;
	opacity: 1;
	position: absolute;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	top: 0;
	visibility: visible;
	animation: welcomeScreen 2s linear forwards;
}

section {
    width: 100%;
    display: table;
    margin: 0;
    max-width: none;
	background: #ccc;
    height: 100vh;
}

.loadingText {
	margin-top: 70px;
	margin-left: -10px;
}

.boxLoading {
	width: 50px;
	height: 50px;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.boxLoading:before {
	content: '';
	width: 50px;
	height: 5px;
	background: #000;
	opacity: 0.1;
	position: absolute;
	top: 59px;
	left: 0;
	border-radius: 50%;
	animation: shadow 0.5s linear infinite;
}
.boxLoading:after {
	content: '';
	width: 50px;
	height: 50px;
	background: #1A6844;
	animation: rotate 0.5s linear infinite;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 3px;
}

@keyframes welcomeScreen {
	0%,
	80% {
		opacity: 1;
		height: 100vh;
	 }
	90% {
		opacity: 0.9;
		height: 100vh;
	}
	100% {
		opacity: 0;
		height: 0;
		position: relative;
		visibility: hidden;
	}
}
@keyframes rotate {
	17% {
		border-bottom-right-radius: 3px;
	}
	25% {
		transform: translateY(9px) rotate(22.5deg);
	}
	50% {
		transform: translateY(18px) scale(1, 0.9) rotate(45deg);
		border-bottom-right-radius: 40px;
	}
	75% {
		transform: translateY(9px) rotate(67.5deg);
	}
	100% {
		transform: translateY(0) rotate(90deg);
	}
}
@keyframes shadow {
	0%,
	100% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1.2, 1);
	}
}

