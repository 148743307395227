.introductionContainer {
    display: flex;
    flex-direction: column;
    background: #ffef96BB;
    max-width: 1000px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    border-radius: 6px;
    color: #023246;
    text-align: center;
}
.introductionTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 5px 15px;
}

.introductionText {
    font-size: 16px;
    margin: 5px 10px;
    padding-bottom: 10px;
}

p{
  margin: 0;
}
